<template>
  <div>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Mensajes enviados
    </div>

    <v-btn
      color="primary"
      class="ml-4 mr-4 mt-4"
      @click="nuevo"
      style="width:100px"
      >
      Nuevo
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="mensajes"
      :options.sync="options"
      :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
      :server-items-length="totalMensajes"
      :loading="loading"
      style="overflow: scroll"
      mobile-breakpoint="0"
      class="elevation-1"
      dense
      @click:row="seleccion"
      show-expand
      single-expand
      :expanded-sync="expanded"
      item-key="MsjId"
      >
      <template v-slot:item.MsjEnv="{ item }">
        <v-simple-checkbox v-model="item.MsjEnv" disabled></v-simple-checkbox>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td colspan="2">
        </td>
        <td>
           <table v-for="(elemMsjPer, indexMsjPer) in item.MsjPer" :key="indexMsjPer">
            <tr>
              <td>{{ elemMsjPer.PerNom }}</td>
            </tr>
           </table>
           <table v-for="(elemMsjPerCat, indexMsjPerCat) in item.MsjPerCat" :key="indexMsjPerCat">
            <tr>
              <td>{{ elemMsjPerCat.PerCatNom }}</td>
            </tr>
           </table>
        </td>
        <td>
        </td>
        <td>
           <table v-for="(elemMsjLec, indexMsjLec) in item.MsjLec" :key="indexMsjLec">
            <tr>
              <td>{{ elemMsjLec.PerNom }}</td>
            </tr>
           </table>
        </td>
        <td>
           <table v-for="(elemMsjLec, indexMsjLec) in item.MsjLec" :key="indexMsjLec">
            <tr>
              <td>{{ elemMsjLec.MsjLecFec }}</td>
            </tr>
           </table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapState, mapMutations, mapActions } from "vuex";
  import funcs from "../funcs";

  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz']),
        ...mapState('MensajesEnviados', ['entidadActual'])
    },
    data () {
      return {
        expanded: [],
        totalMensajes: 0,
        mensajes: [],
        loading: true,
        options: {itemsPerPage: 15},
        headers: [
          { text: 'Fecha', width: 150, sortable: false, value: 'MsjFecFormatted' },
          { text: 'Asunto', width:300, sortable: false, value: 'MsjNom' },
          { text: 'Para', width: 250, class: 'col_para', sortable: false, value: 'MsjPara' },
          { text: 'Enviado', width: 100, sortable: false, value: 'MsjEnv' },
          { text: 'Leído por', width: 250, sortable: false, value: 'MsjLeido' },
          { text: 'Fecha lec.', width: 170, sortable: false, value: 'FecLec' },
          { text: '', value: 'data-table-expand'}
        ],
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
            .then(data => {
              this.mensajes = data.items
              this.totalMensajes =  data.total
            })
        },
        deep: true,
      },
    },
    mounted () {
      this.getDataFromApi()
        .then(data => {
          this.mensajes = data.items
          this.totalMensajes = data.total
        })

      setInterval(this.actualizarMensajes, 900000);
    },
    methods: {
      ...mapMutations('MensajesEnviados', ['entidadActualSet']),
      ...mapActions('MensajesEnviados', ['nuevaEntidad']),
      seleccion(itm){ 
        this.entidadActualSet(itm);
        this.$router.push('/enviar-mensajes-edicion')
      },
      nuevo(){
        this.nuevaEntidad();
      },
      actualizarMensajes() {
        this.getDataFromApi()
            .then(data => {
              this.mensajes = data.items
              this.totalMensajes =  data.total
            })
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve) => {
          const { sortBy, sortDesc } = this.options

//          let items = this.getMensajes()

      // var CampoBusqueda = "";
      // var ValorBuscar = "";
      var NumRegsPag = this.options.itemsPerPage;
      var NumPag = this.options.page;
      
      // var controllerParameters = {
      //           Action: "GET_MENSAJES_ENVIADOS",   // de momento no se contempla este valor. 
      //           ParameterList: [
      //               { Type: "PARAMETER", Name: "CampoBusqueda", Operator: "=", Value: CampoBusqueda },
      //               { Type: "PARAMETER", Name: "ValorBuscar", Operator: "=", Value: ValorBuscar },
      //               { Type: "PARAMETER", Name: "NumRegsPag", Operator: "=", Value: NumRegsPag },
      //               { Type: "PARAMETER", Name: "NumPag", Operator: "=", Value: NumPag },
      //               { Type: "PARAMETER", Name: "PerId", Operator: "=", Value: this.perId }
      //           ]
      //       } ;       
      var controllerParameters = {
                Action: "GET_MENSAJES_ENVIADOS",   // de momento no se contempla este valor.
                NumRegsPag: NumRegsPag,
                NumPag: NumPag,
                PerId: this.perId 
            } ;       

      // axios({ method: "POST", "url": this.urlRaiz + "/api/mensajes", "data": JSON.stringify(controllerParameters), "headers": { "content-type": "application/json" } }).then(result => {
      var AuthToken = localStorage.getItem('token');
      axios({ method: "POST", "url": this.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
            //  let items = result.data.Data;
            // let items = result.data;
            let items = [];
            result.data.EntsList.forEach(element => {
              // var itemObject = {MsjFec: element.MsjFec, MsjNom: element.MsjNom, MsjPara: element.MsjPara, MsjEnv: element.MsjEnv};
              // var Fecha = new Date(element.MsjFec);
              // var FechaStr = Fecha.getDate().toString().padStart(2, "0") + "/" + (Fecha.getMonth() + 1).toString().padStart(2, "0") + "/" + Fecha.getFullYear().toString() + " " + Fecha.getHours().toString().padStart(2, "0") + ":" + Fecha.getMinutes().toString().padStart(2, "0");

              var FechaStr = funcs.UtcDateTimeToStr(element.MsjFec, "datetime");


              var ParaStr = "";
              if (element.MsjPer.length >= 1) {
                ParaStr = element.MsjPer[0].PerNom;
              }
              else if (element.MsjPerCat.length >= 1) {
                ParaStr = element.MsjPerCat[0].PerCatNom;
              }
              if ((element.MsjPer.length + element.MsjPerCat.length) > 1) {
                ParaStr = ParaStr + ", . . . ";
              }

              var LecStr = "";
              var FecLecStr = "";
              if (element.MsjLec.length >= 1) {
                element.MsjLec.forEach(elemMsjLec => {
                    var MsjLecFec = new Date(elemMsjLec.MsjLecFec);
                    var MsjLecFecStr = MsjLecFec.getDate().toString().padStart(2, "0") + "/" + (MsjLecFec.getMonth() + 1).toString().padStart(2, "0") + "/" + MsjLecFec.getFullYear().toString() + " " + MsjLecFec.getHours().toString().padStart(2, "0") + ":" + MsjLecFec.getMinutes().toString().padStart(2, "0");

                    if (LecStr == "") {
                      LecStr = MsjLecFecStr;
                    }
                    elemMsjLec.MsjLecFec = MsjLecFecStr;
                })

                LecStr = element.MsjLec[0].PerNom;
                FecLecStr = element.MsjLec[0].MsjLecFec;
              }
              if (element.MsjLec.length > 1) {
                LecStr = LecStr + ", . . . ";
                FecLecStr = FecLecStr + ", . . . "
              }

              var itemObject = {MsjFecFormatted: FechaStr, MsjNom: element.MsjNom, MsjPara: ParaStr, MsjPer: element.MsjPer, MsjPerCat: element.MsjPerCat, MsjCatMsj: element.MsjCatMsj, MsjEnv: element.MsjEnv, MsjLeido: LecStr, MsjLec: element.MsjLec, MsjLecCnd: element.MsjLecCnd, FecLec: FecLecStr, MsjTxt: element.MsjTxt, EmpId: element.EmpId, MsjId: element.MsjId, PerId: element.PerId, MsjFec: element.MsjFec, MsjFic: element.MsjFic };
              items.push(itemObject);

            });

            // let items = result.data.EntsList;

            // const total = 10;
            const total = result.data.EntsTotCount;

            // const total = result.data.RowsCount; // items.length;

          if (sortBy.length === 1 && sortDesc.length === 1) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          setTimeout(() => {
            this.loading = false
            resolve({
              items,
              total,
            })
          }, 1000)
          });
        })
      },
    },
  }
</script>
<style>
  .col_para {
    min-width: 250px;
  }
</style>